iframe {
  height: 100vh;
  width: 100vw;
}

#button {
  position: absolute;
}

.buttonGroup {
  padding: 1vh;
  justify-content: center;
}

.filter-wrapper {
  border-bottom: 1px solid black;
  padding-top: 1vh;
}

.filter-container {
    display: flex;
    justify-content: center;
    gap: 5vw;
    align-items: center;
    padding: 0.5vh;
}

.filter-container div {
    display: flex;
    flex-direction: column;
}

#select {
  width: 10vw;
}

.pagination {
  /* margin: 1vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.questionContainer {
  padding-left: 1vw;
  padding-right: 1vw;
}

.problemContainer {
  margin-top: 2vh;
  padding-bottom: 1vh;
  /* border-bottom: 1px solid black; */
}

.questionItem {
  margin-top: 2vh;
}

.questionContent {
  /* display: flex; */
  align-items: left;
  justify-content: left;
  padding: 1vh;
}

.optionContainer {
  display: flex;
  justify-content: last baseline;
  align-items: center;
}

.questionHeader {
  display: flex;
  justify-content: last baseline;
  align-items: center; 
  gap: 5vh;
}